.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #bce4fa;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #ffffff;
}

.transparent {
  background-color: transparent !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.login-container {
  display: flex;
}

.nav-bar {
  background-color: #c10719;
}

.logged-user {
  color: #ffffff;
}

.error {
  color: #ed1c23;
}

.z-index-0 {
  z-index: 99999;
}

.pointer {
  cursor: pointer;
}

.thumbnail {
  height: 100px;
}

.margin-top-10px {
  margin-top: 10px;
}

.text-area {
  resize: none;
}

.height-70 {
  height: 70px;
}

.table-head {
  background-color: #4096ee;
  color: white;
}

.button-standard {
  background-color: #4096ee;
  color: white;
}

.heading {
  color: #4096ee;
}

.black {
  color: black;
}

.declined-meeting-tr {
  background-color: #ed1c23;
  color: #ffffff;
}

.bold-text {
  font-weight: bold;
}

#dropdown-basic {
  border: 0;
  background-color: transparent;
  color: white;
  margin-top: -6px;
  font-size: 18px;
}

.reports-card {
  margin-bottom: 30px;
}

.reports-card-right-info {
  width: 210px;
}

.reports-card-left-info {
  width: 90px;
}

.report-summury-card {
  margin-top: 30px;
  margin-bottom: 30px;
}

.list-group-item {
  background-color: #efefef !important;
}

.text-center {
  text-align: center;
}

.table-x-scroll {
  display: block;
  overflow-x: auto;
}

.avatar {
  border-radius: 50%;
  width: 70px;
  height: 70px;
  margin-right: 30px;
}
